@use '~/src/overtime-lib/src/www/lib/mixins';

.TeamCoach {
	.Profile {
		padding: var(--size-gap-2x);
		color: var(--color-white);

		.Placeholder {
			padding: 20%;
		}
		@include mixins.desktop {
			.ProfileContainer {
				display: grid;
				grid-template-columns: 300px 1fr;
				column-gap: var(--size-gap);
				justify-content: center;
				align-items: center;
			}
		}
		h1 {
			margin: var(--size-gap) 0;
			line-height: 1.1;
			@include mixins.desktop {
				display: flex;
				flex-direction: row;
				gap: 1ex;
				margin-top: 0;
			}
		}
		.Position {
			margin-bottom: var(--size-gap);
			font-family: var(--font-highlight);
		}
		label {
			font-family: var(--font-highlight);
		}

		.Hometown {
			color: var(--color-white);
		}
		.ImageContainer {
			width: fit-content;
		}
		img {
			display: block;
			width: 100%;
			max-width: 320px;
		}
		.Rule {
			margin: var(--size-gap) 0;
			width: 100%;
			height: 1px;
		}
		.Vitals {
			display: grid;
			grid-template-columns: 1fr 1fr;
			row-gap: var(--size-gap-half);
			margin-bottom: var(--size-gap);
			@include mixins.desktop {
				display: flex;
				flex-direction: row;
				gap: var(--size-gap-4x);
			}
		}
	}
	.Biography {
		padding: var(--size-gap);
		p {
			line-height: 1.7;
		}
	}
}
