@use '~/src/overtime-lib/src/www/lib/mixins';

.TeamStatistics {
	background-color: var(--color-lightgray);
	padding: var(--size-gap);

	h3 {
		padding-bottom: var(--size-gap-half);
	}

	.BoxScore {
		padding-bottom: var(--size-gap-2x);
	}

	.Game {
		color: var(--color-black);
		font-size: var(--font-small);
		> div {
			white-space: pre-line;
			@include mixins.max-lines(2);
		}
		&:hover {
			text-decoration: none;
		}
		.Date {
			color: var(--color-gray);
			font-weight: normal;
		}
	}
}
