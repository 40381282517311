.TeamAbout {
	padding: var(--size-gap);

	.Description {
		p.Highlight {
			color: inherit;
			font-weight: 600;
			font-family: var(--font-highlight);
		}
		p {
			color: var(--color-white);
		}
	}

	label {
		display: block;
		margin-top: var(--size-gap-2x);
		margin-bottom: var(--size-gap-half);
		font-family: var(--font-highlight);
	}
	.SocialContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: var(--size-gap);
	}
}
