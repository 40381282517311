.TeamAbout {
  padding: var(--size-gap);
}

.TeamAbout .Description p.Highlight {
  color: inherit;
  font-weight: 600;
  font-family: var(--font-highlight);
}

.TeamAbout .Description p {
  color: var(--color-white);
}

.TeamAbout label {
  margin-top: var(--size-gap-2x);
  margin-bottom: var(--size-gap-half);
  font-family: var(--font-highlight);
  display: block;
}

.TeamAbout .SocialContainer {
  align-items: center;
  gap: var(--size-gap);
  flex-direction: row;
  display: flex;
}

.TeamCoach .Profile {
  padding: var(--size-gap-2x);
  color: var(--color-white);
}

.TeamCoach .Profile .Placeholder {
  padding: 20%;
}

@media (width >= 1024px) {
  .TeamCoach .Profile .ProfileContainer {
    column-gap: var(--size-gap);
    grid-template-columns: 300px 1fr;
    justify-content: center;
    align-items: center;
    display: grid;
  }
}

.TeamCoach .Profile h1 {
  margin: var(--size-gap) 0;
  line-height: 1.1;
}

@media (width >= 1024px) {
  .TeamCoach .Profile h1 {
    flex-direction: row;
    gap: 1ex;
    margin-top: 0;
    display: flex;
  }
}

.TeamCoach .Profile .Position {
  margin-bottom: var(--size-gap);
  font-family: var(--font-highlight);
}

.TeamCoach .Profile label {
  font-family: var(--font-highlight);
}

.TeamCoach .Profile .Hometown {
  color: var(--color-white);
}

.TeamCoach .Profile .ImageContainer {
  width: fit-content;
}

.TeamCoach .Profile img {
  width: 100%;
  max-width: 320px;
  display: block;
}

.TeamCoach .Profile .Rule {
  margin: var(--size-gap) 0;
  width: 100%;
  height: 1px;
}

.TeamCoach .Profile .Vitals {
  row-gap: var(--size-gap-half);
  margin-bottom: var(--size-gap);
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media (width >= 1024px) {
  .TeamCoach .Profile .Vitals {
    gap: var(--size-gap-4x);
    flex-direction: row;
    display: flex;
  }
}

.TeamCoach .Biography {
  padding: var(--size-gap);
}

.TeamCoach .Biography p {
  line-height: 1.7;
}

.Navigation {
  background-color: var(--color-white);
}

.TeamRoster {
  gap: var(--size-gap);
  padding: var(--size-gap);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media (width >= 1024px) {
  .TeamRoster {
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
  }
}

.TeamSchedule {
  background-color: var(--color-lightgray);
}

.TeamSchedule .NoGames {
  margin-top: var(--size-gap);
}

.TeamScores {
  background-color: var(--color-lightgray);
}

.TeamScores .NoGames {
  margin-top: var(--size-gap);
}

.TeamStatistics {
  background-color: var(--color-lightgray);
  padding: var(--size-gap);
}

.TeamStatistics h3 {
  padding-bottom: var(--size-gap-half);
}

.TeamStatistics .BoxScore {
  padding-bottom: var(--size-gap-2x);
}

.TeamStatistics .Game {
  color: var(--color-black);
  font-size: var(--font-small);
}

.TeamStatistics .Game > div {
  white-space: pre-line;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.TeamStatistics .Game:hover {
  text-decoration: none;
}

.TeamStatistics .Game .Date {
  color: var(--color-gray);
  font-weight: normal;
}



/*# sourceMappingURL=Team.daa1cebf.css.map */
