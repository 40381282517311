@use '~/src/overtime-lib/src/www/lib/mixins';

.TeamRoster {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: var(--size-gap);
	padding: var(--size-gap);
	@include mixins.desktop {
		grid-template-columns: repeat(4, 1fr);
		justify-content: center;
	}
}
